<template>
  <div class="info_warp">

    <TopCard text="帖子详情"
             ref="TopCardRef">
      <template #right>
        <div class="fixed_btn"
             @click="huifu">
          回 复</div>
      </template>
    </TopCard>
    <div style=" overflow: scroll;">
      <!-- question -->
      <div class="tiwen">
        <div class="head">
          <div>
            <img src="@/static/dayi/toux.jpg"
                 alt="" />
            {{ info.discuss_parent.discuss_title || '' }}
          </div>
          <div>
            {{ info.discuss_parent.discuss_time }}
          </div>
        </div>
        <div class="content">
          <div class='left'>
            <div>
              {{ info.discuss_parent.discuss_content }}
            </div>

            <div class="images">
              <!-- <img :src="item"
                 v-for="(item,index) in info.discuss_parent.discuss_image"
                 :key="index"
                 alt=""> -->
              <div v-for="(item, index) in info.discuss_parent.discuss_image"
                   :key="index">
                <a-image width="120rem"
                         height="120rem"
                         :src="item"
                         :preview-src-list="info.discuss_parent.discuss_image">
                </a-image>
              </div>
            </div>

          </div>
          <div class="right">
            <img src="@/static/dayi/icon-未点赞@2x.png"
                 @click="dianzan"
                 v-if="show"
                 alt="" />
            <img src="@/static/dayi/icon-已点赞@2x.png"
                 v-else
                 alt="" />
            <span>
              {{ info.discuss_parent.discuss_upvote_times }}
            </span>
          </div>

        </div>

        <!-- associated question -->
        <div class="associated_question"
             v-if="info.question_data.question_content">
          <div class="header">
            <div class="button"
                 @click="isShowAssociatedQuestion = !isShowAssociatedQuestion">
              <div class="button-item"
                   v-show="isShowAssociatedQuestion">
                <div>查看题目</div>
                <img src="@/static/dayi/pull-down.png"
                     alt="" />
              </div>
              <div class="button-item"
                   v-show="!isShowAssociatedQuestion">
                <div>隐藏题目</div>
                <img src="@/static/dayi/pull-up.png"
                     alt="" />
              </div>
            </div>
          </div>
          <!-- question body -->
          <div class="body"
               v-show="!isShowAssociatedQuestion">
            <div class="title">题目内容</div>
            <div class="content border-radius"
                 v-html="info.question_data.question_content"></div>
            <div class="title">题目答案</div>
            <div class="content border-radius"
                 v-html="info.question_data.question_answer"></div>
            <div class="title">题目解析</div>
            <div class="content "
                 v-html="info.question_data.question_explain"></div>
          </div>
        </div>
      </div>

      <div class="tiwen"
           style="margin-top:20px"
           v-if="info.discuss_parent.review_status === -1">
        <div class="head">
          <div>
            <img src="@/static/dayi/toux.jpg"
                 alt="" />
            {{ info.discuss_parent.discuss_title || '' }}
          </div>
          <div>
            {{ info.discuss_parent.review_time }}
          </div>
        </div>

        <div class="reject_warp">
          <div class="reject_content">
            未通过原因:{{info.discuss_parent.reason}}
          </div>
          <div class="reject_btn">
            审核不通过
          </div>
        </div>
      </div>

      <div class="common"
           v-if="info.volist_children.length">
        <div class="huida"
             v-for="(item, index) in info.volist_children"
             :key="index">
          <div v-if="item.review_status == 1">
            <div class="head">
              <div>
                <img src="@/static/dayi/toux.jpg"
                     alt="" />
                {{ item.user_id || '' }}
              </div>
              <div>
                {{ item.discuss_time }}
              </div>
            </div>
            <div class="content">
              <div class="left">
                <div v-html="item.discuss_content">
                </div>

                <div class="images">
                  <div v-for="(item, index) in item.discuss_image"
                       :key="index">
                    <a-image width="120rem"
                             height="120rem"
                             :src="item">
                    </a-image>
                  </div>
                </div>
                <div class="right">
                  <img src="@/static/dayi/icon-未点赞@2x.png"
                       @click="dianzanChild(item, index)"
                       v-if="item.show"
                       alt="" />
                  <img src="@/static/dayi/icon-已点赞@2x.png"
                       v-else
                       alt="" />
                  <span>
                    {{ item.discuss_upvote_times }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="head">
              <div>
                <img src="@/static/dayi/toux.jpg"
                     alt="" />
                {{ item.user_id || '' }}
              </div>
              <div>
                {{ item.review_time }}
              </div>
            </div>

            <div class="reject_warp">
              <div class="reject_content">
                未通过原因:{{item.reason}}
              </div>
              <div class="reject_btn">
                审核不通过
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- v-if="info.discuss_parent.review_status !== -1" -->

    <ReplyForm ref="HuifuFormRef"
               title='回复'
               bodyHeight='520rem'
               :textRow='8'
               @submit="submit"
               @cancel='cancel' />
  </div>
</template>

<script setup>
import ReplyForm from './form.vue'
import { message } from 'ant-design-vue'
import { useRoute } from 'vue-router'
// import { instance } from '@/utils/http.js'
import { getPostInfo, addUpvote, replyPost } from '@/api/post.js'
import { getCurrentInstance, onActivated, onDeactivated, onMounted } from 'vue'
import { ref } from 'vue'
import TopCard from '@/views/components/top_card/index.vue'
//const { ctx } = getCurrentInstance()
let route = useRoute()
let info = ref({
  discuss_parent: {
    discuss_title: '',
  },
  question_data: {
    question_content: ''
  },
  volist_children: []
})
let form = ref({})
let show = ref(true)
let isShowAssociatedQuestion = ref(true)
let HuifuFormRef = ref()

const getTieziInfo = async () => {
  let params = JSON.parse(route.query.form)
  form.value = params
  const { data } = await getPostInfo(params)
  data.discuss_parent.discuss_image = data.discuss_parent.discuss_image
    ? JSON.parse(data.discuss_parent.discuss_image)
    : [];
  data.volist_children = data.volist_children.map(item => {
    item.show = true;
    return item;
  });

  data.volist_children.map(item => {
    item.discuss_image = JSON.parse(item.discuss_image);
    return;
  });
  info.value = data;

}
const dianzan = async () => {
  const { data } = addUpvote({
    discuss_id: info.value.discuss_parent.discuss_id
  }).then(() => {
    message.success('点赞成功');
    info.value.discuss_parent.discuss_upvote_times += 1;
    show.value = false;
    // ctx.$forceUpdate()
  });
}
const dianzanChild = async (item, index) => {
  const { data } = await addUpvote({
    discuss_id: item.discuss_id
  })
  message.success('点赞成功');
  info.value.volist_children[index].discuss_upvote_times += 1;
  info.value.volist_children[index].show = false;

}
const huifu = () => {
  let form1 = {
    discuss_parent_id: form.value.discuss_id,
    discuss_image: [],
    discuss_content: ''
  };
  HuifuFormRef.value.images = [];
  HuifuFormRef.value.form = form1;
  HuifuFormRef.value.dialogVisible = true;
}

//发帖弹窗》提交
const submit = async () => {
  const { data } = replyPost(
    HuifuFormRef.value.form1
  ).then(res => {
    message.success('回复成功')
    cancel()
    getTieziInfo()
  })
}
//发帖弹窗》取消
const cancel = () => {
  HuifuFormRef.value.dialogVisible = false
}

onMounted(() => getTieziInfo())
</script>

<style lang="scss" scoped>
//抽出公共部分
.tiwen {
  box-shadow: 0px 1px 10px 2px rgba(226, 223, 223, 0.4);
  background: white;
  padding: 26rem 40rem;
  .head {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 25rem;
    align-items: center;
    div {
      &:nth-child(1) {
        font-size: 24rem;
        font-weight: bold;
        color: #333333;
        display: flex;
        align-items: center;
        text-indent: 5px;
        img {
          width: 40rem;
          height: 40rem;
          margin-right: 20rem;
        }
      }
      &:nth-child(2) {
        font-size: 20rem;
        font-weight: 500;
        color: #999999;
      }
    }
  }
  .content {
    margin: 0 auto;
    margin-top: 19rem;
    font-size: 24rem;
    font-weight: 500;
    color: #333333;
    position: relative;
    .left {
      width: calc(100% - 140rem);
      padding: 0 20rem;
    }

    .images {
      position: relative;
      margin-top: 20rem;
      div {
        margin-right: 20rem;
        display: inline-block;
      }
      img {
        width: 120rem;
        height: 120rem;
      }
    }
    .right {
      display: flex;
      align-items: center;
      font-size: 32rem;
      position: absolute;
      right: 20rem;
      top: 0rem;
      img {
        width: 32rem;
        height: 32rem;
        margin-right: 20rem;
        cursor: pointer;
      }
    }
  }
}
.info_warp {
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;
  .tiwen {
    @extend .tiwen;
    margin-bottom: 30rem;
    .associated_question {
      padding: 17px 20px 0 20px;
      .header {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .button {
          padding: 8rem;
          display: inline-block;
          font-weight: bold;
          font-size: 24rem;
          color: #3f9eff;
          cursor: pointer;
          .button-item {
            display: flex;
            align-items: center;
            img {
              width: 24rem;
              height: 16rem;
              margin-left: 16rem;
              margin-top: 6rem;
            }
            div {
              margin-right: 5px;
            }
          }
        }
      }
      .body {
        .title {
          position: relative;
          margin: 10px 7px;
          font-weight: bold;
          font-size: 24rem;
        }
        // 左边框
        .title::before {
          content: "";
          position: absolute;
          display: inline-block;
          top: 9rem;
          left: -24rem;
          width: 5rem;
          height: 22rem;
          border-radius: 5rem;
          background-color: #2196f3;
        }
        .content {
          margin: 10px 7px;
          padding: 7px;
          line-height: 1.25em;
        }
        .border-radius {
          border-bottom: 1px solid #e9e9e9;
        }
      }
    }
  }
  .common {
    box-shadow: 0px 1px 5px 1px rgba(226, 223, 223, 0.4);
    margin-top: 20px;
    .huida {
      @extend .tiwen;
    }
  }
  //回复按钮
  .fixed_btn {
    position: absolute;
    width: 140rem;
    height: 54rem;
    background: #2196f3;
    border-radius: 35rem;
    margin-right: 45rem;
    right: 0rem;
    top: 23rem;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 54rem;
    font-size: 24rem;
    cursor: pointer;
  }
}
.reject_warp {
  display: flex;
  height: 108px;
  .reject_content {
    flex: 1;
    font-weight: bold;
    font-size: 16px;
    text-indent: 20px;
    margin-top: 15px;
  }
  .reject_btn {
    width: 115px;
    margin-right: 20px;
    margin-top: 15px;
    border-radius: 18px;
    height: 35px;
    background: #fedddd;
    font-weight: bold;
    color: #ff664e;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
